const lv = {
    'welcomeScreenHeading': 'Laipni lūdzam',
    'requiredCheckboxMessage': 'Lūdzu, apstipriniet izvēles rūtiņu',
    'pleaseSelect': 'Lūdzu, izvēlieties…',
    'cancel': 'Atcelt',
    'ok': 'Labi',
    'yes': 'Jā',
    'back': 'Atpakaļ',
    'welcome': 'Laipni lūdzam',
    'no': 'Nē',
    'pleaseEnter': 'Lūdzu, ievadiet…',
    'thanksForYourCheckin': 'Paldies par reģistrēšanos.',
    'checkoutConfirmTitle': 'Apstipriniet izrakstīšanos',
    'checkoutConfirmMessage': 'Lūdzu, izrakstieties tikai tad, kad esat pabeidzis apmeklējumu.',
    'yesCheckout': 'Izrakstīties tagad',
    'de': 'Deutsch',
    'en': 'English',
    'es': 'Español',
    'fr': 'Français',
    'it': 'Italiano',
    'pt': 'Português',
    'jp': '日本語',
    'nl': 'Nederlands',
    'pl': 'Polski',
    'ru': 'Русский',
    'tr': 'Türkçe',
    'cn': '中国人',
    'hu': 'Magyarország',
    'cz': 'Česká republika',
    'si': 'Slovenija',
    'ua': 'Україна (Ukrayina)',
    'ro': 'România',
    'hr': 'Hrvatska',
    'bg': 'Български',
    'sk': 'Slovenský',
    'lv': 'Latviešu',
    'lt': 'Lietuvių',
    'el': 'Ελληνικά',
    'ar': 'العربية',
    'thanksForYourVisit': 'Paldies par jūsu apmeklējumu.',
    'youCanCloseThisPageNow': 'Tagad varat aizvērt šo lapu.',
    'checkedOutViewTitle': 'Jūs esat izrakstījies',
    'checkoutMessage': 'Mēs jūs izrakstām…',
    'checkout': 'Izrakstīties',
    'invalidNameFormat': 'Lūdzu, ievadiet pilnu vārdu.',
    'clickToSign': 'nospiediet, lai parakstītu',
    'invalidQrCodeHeading': 'Nederīgs QR kods',
    'invalidQrCodeText': 'Lūdzu, sazinieties ar reģistratūras personālu.',
    'checkedOut': 'Izrakstījies',
    'checkinDeniedHeading': 'Mēs diemžēl nevaram jūs reģistrēt.',
    'next': 'Tālāk',
    'groupSelectionHeading': 'Izvēlieties apmeklētāju grupu:',
    'checkinMessage': 'Mēs jūs reģistrējam…',
    'aServiceOf': 'Pakalpojumu nodrošina',
    'checkedIn': 'Reģistrēts',
    'CheckedInInfo': 'Lūdzu, atstājiet šo lapu atvērtu, lai izrakstītos pēc apmeklējuma.',
    'checkin': 'Reģistrēties',
    'delete': 'Dzēst',
    'pleaseCheckYourInput': 'Lūdzu, pārbaudiet ievadīto informāciju',
    'signAndProceed': 'Parakstīt un turpināt',
    'signatureRequiredMessage': 'Lūdzu, parakstiet dokumentu',
    'requiredField': 'Obligāts lauks',
    'invalidEmailMessage': 'Lūdzu, ievadiet derīgu e-pasta adresi',
    'pleaseEnterANumber': 'Lūdzu, ievadiet skaitli',
    'pleaseEnterAValidNumber': 'Lūdzu, ievadiet derīgu veselu skaitli',
    'invalidUrlMessage': 'Lūdzu, ievadiet derīgu URL, piemēram, https://www.example.com',
    'functionsError.noSubscriptionFound': 'Mēs pašlaik nevaram jūs reģistrēt. Lūdzu, sazinieties ar reģistratūras personālu.',
    'checkinLoading': 'Reģistrēšanās…',
    'functionsError.denyOnValidationFailed': 'Mēs nevaram jūs reģistrēt, pamatojoties uz jūsu sniegto informāciju. Lūdzu, sazinieties ar reģistratūras personālu.',
    'functionsError.missingRequiredInput': 'Tā kā neesat aizpildījis visus obligātos laukus, mēs nevaram jūs reģistrēt. Lūdzu, sazinieties ar reģistratūras personālu.',
    'functionsError.invalidInputData': 'Jūs esat ievadījis nederīgu informāciju. Lūdzu, sazinieties ar reģistratūras personālu',
    'functionsError.checkinTokenExpired': 'Jūsu reģistrācijas saite ir beigusies. Lūdzu, vēlreiz noskenējiet QR kodu vai sazinieties ar reģistratūras personālu.',
    'startCamera': 'Pieskarieties, lai aktivizētu kameru.',
    'cameraLoading': 'Ielādē…',
    'photoSectionTitle': 'Lūdzu, uzņemiet savu fotoattēlu reģistrācijai.',
    'photoSectionDescription': 'Piekļuve kamerai un fotoattēli tiks izmantoti tikai, lai pārbaudītu jūsu identitāti reģistrācijai.',
    'takePhoto': 'Uzņemt fotoattēlu',
    'reTakePhoto': 'Uzņemt vēlreiz',
    'cameraForbidden': 'Piekļuve kamerai ir liegta.',
    'fullname': 'Pilns vārds',
    'Details': 'Informācija',
  };
  
  export { lv };