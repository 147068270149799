const de = {
  'welcomeScreenHeading': 'Willkommen',
  'requiredCheckboxMessage': 'Bitte bestätigen Sie die Checkbox',
  'pleaseSelect': 'Bitte auswählen…',
  'cancel': 'Abbrechen',
  'ok': 'OK',
  'yes':'Ja',
  'back': 'Zurück',
  'welcome': 'Willkommen',
  'no':'Nein',
  'pleaseEnter': 'Bitte eingeben…',
  'thanksForYourCheckin': 'Danke für Ihre Anmeldung.',
  'checkoutConfirmTitle': 'Abmeldung bestätigen',
  'checkoutConfirmMessage': 'Bitte melden Sie sich erst ab, wenn Sie Ihren Besuch beendet haben.',
  'yesCheckout': 'Jetzt abmelden',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'it': 'Italiano',
  'pt': 'Português',
  'jp': '日本語',
  'nl': 'Nederlands',
  'pl': 'Polski',
  'ru': 'Русский',
  'tr': 'Türkçe',
  'cn': '中国人',
  'hu': 'Magyarország',
  'cz': 'Česká republika',
  'si': 'Slovenija',
  'ua': 'Україна (Ukrayina)',
  'ro': 'România',
  'hr': 'Hrvatska',
  'bg': 'Български',
  'sk': 'Slovenský',
  'lv': 'Latviešu',
  'lt': 'Lietuvių',
  'el': 'Ελληνικά',
  'ar': 'العربية',
  'thanksForYourVisit': 'Danke für Ihren Besuch.',
  'youCanCloseThisPageNow': 'Sie können diese Seite nun schließen.',
  'checkedOutViewTitle': 'Sie sind abgemeldet',
  'checkoutMessage': 'Wir melden Sie ab…',
  'checkout': 'Abmelden',
  'invalidNameFormat': 'Bitte vollständigen Namen angeben.',
  'clickToSign': 'klicken um zu unterschreiben',
  'invalidQrCodeHeading': 'Ungültiger QR-Code',
  'invalidQrCodeText': 'Bitte wenden Sie sich an das Empfangspersonal.',
  'checkedOut': 'Abgemeldet',
  'checkinDeniedHeading': 'Wir können Sie leider nicht anmelden.',
  'next': 'Weiter',
  'groupSelectionHeading': 'Wählen Sie eine Besuchergruppe:',
  'checkinMessage': 'Wir melden Sie an…',
  'aServiceOf': 'Ein Service von',
  'checkedIn': 'Angemeldet',
  'CheckedInInfo': 'Bitte lassen Sie diese Seite geöffnet, um sich nach Abschluss Ihres Besuchs wieder abzumelden.',
  'checkin': 'Anmelden',
  'delete': 'Löschen',
  'pleaseCheckYourInput': 'Bitte überprüfen Sie Ihre Eingaben',
  'signAndProceed': 'Unterschreiben',
  'signatureRequiredMessage': 'Bitte unterschreiben Sie das Dokument',
  'requiredField': 'Pflichtfeld',
  'invalidEmailMessage': 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
  'pleaseEnterANumber': 'Bitte geben Sie eine Zahl ein',
  'pleaseEnterAValidNumber': 'Bitte geben Sie eine gültige Ganzzahl ein',
  'invalidUrlMessage': 'Bitte eine gültige Url eingeben z.B. https://www.example.com',
  'functionsError.noSubscriptionFound': 'Wir können Sie im Moment nicht anmelden. Bitte wenden Sie sich an das Empfangspersonal.',
  'checkinLoading': 'Anmelden…',
  'functionsError.denyOnValidationFailed': 'Wir können Sie aufgrund ihrer Angaben nicht anmelden. Bitte wenden Sie sich an das Empfangspersonal.',
  'functionsError.missingRequiredInput': 'Da sie nicht alle Pflichtfelder ausgefüllt haben, können wir Sie nicht anmelden. Bitte wenden Sie sich an das Empfangspersonal.',
  'functionsError.invalidInputData': 'Sie haben ungültige Angaben gemacht. Bitte wenden Sie sich an das Empfangspersonal',
  'functionsError.checkinTokenExpired': 'Ihr Anmeldelink ist abgelaufen. Bitte scannen sie den Qr-Code erneut oder wenden Sie sich an das Empfangspersonal.',
  'startCamera': 'Tippen, um Kamera zu aktivieren.',
  'cameraLoading': 'Lädt…',
  'photoSectionTitle': 'Bitte machen Sie zur Anmeldung ein Foto von sich.',
  'photoSectionDescription': 'Der Zugriff auf die Kamera und das Foto werden ausschließlich zur Überprüfung Ihrer Identität für die Anmeldung verwendet.',
  'takePhoto': 'Foto aufnehmen',
  'reTakePhoto': 'Erneut aufnehmen',
  'cameraForbidden': 'Zugriff auf die Kamera verweigert.',
  'fullname': 'Vollständiger Name',
  'Details': 'Details',
  'visitorIdCardWIllBePrinted': 'Ihr Besucherausweis wird gedruckt...',
};

export { de };