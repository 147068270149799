import type { FirebaseFunctions } from '@einfachgast/shared';
import { FirebaseCloudFunctions, type IFirebaseCloudFunctions } from './firebase-cloud-functions';
import type { IVisitApi } from '../interfaces/api/i-visit-api';
import type { CheckinVisitFragment } from '@/types/checkin-visit-fragment';

export class VisitApi implements IVisitApi {
  constructor (private _firebaseFunctions: IFirebaseCloudFunctions = new FirebaseCloudFunctions()){}

  async checkin (data: {visit: CheckinVisitFragment, token: string, photo?: string }): Promise<{ token: string, generateVisitorIdCard: boolean }> {
    const { token, generateVisitorIdCard } = await this._firebaseFunctions.call
      <
        { visit: CheckinVisitFragment, token: string, photo?: string },
        { token: string, generateVisitorIdCard: boolean }
      >
      ('scannerCheckin' as FirebaseFunctions, data);
    return { token, generateVisitorIdCard };
  }

  async checkout (token: string): Promise<void> {
    await this._firebaseFunctions.call<string, void>('scannerCheckout' as FirebaseFunctions, token);
  }

  async generateVisitorIdCard (token: string): Promise<string> {
    return await this._firebaseFunctions.call<string, string>('scannerGenerateVisitorIdCard' as FirebaseFunctions, token);
  }

  async verifyPendingVisit (token: string): Promise<boolean> {
    // no Token? not Checked in.
    if (!token) {
      return false;
    }
    return await this._firebaseFunctions.call<string, boolean>('verifyPendingVisit' as FirebaseFunctions, token);
  }
}
