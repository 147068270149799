import { createI18n } from 'vue-i18n';
import { de } from './translations/de';
import { en } from './translations/en';
import { es } from './translations/es';
import { fr } from './translations/fr';
import { it } from './translations/it';
import { jp } from './translations/jp';
import { nl } from './translations/nl';
import { pl } from './translations/pl';
import { ru } from './translations/ru';
import { tr } from './translations/tr';
import { cn } from './translations/cn';
import { pt } from './translations/pt';
import { hu } from './translations/hu';
import { si } from './translations/si';
import { cz } from './translations/cz';
import { ua } from './translations/ua';
import { ro } from './translations/ro';
import { hr } from './translations/hr';
import { bg } from './translations/bg';
import { sk } from './translations/sk';
import { lv } from './translations/lv';
import { lt } from './translations/lt';
import { el } from './translations/el';
import { ar } from './translations/ar';
import { getDefaultLanguage } from '@/helpers/default-language-helper';

const messages = {
  de,
  en,
  es,
  fr,
  it,
  jp,
  nl,
  pl,
  ru,
  tr,
  cn,
  pt,
  hu,
  cz,
  ua,
  ro,
  hr,
  si,
  bg,
  sk,
  lv,
  lt,
  el,
  ar,
};

const i18n = createI18n({
  locale: getDefaultLanguage(),
  legacy: false,
  globalInjection: true,
  fallbackLocale: 'en',
  messages,
});

export { i18n };